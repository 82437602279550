/* eslint-disable no-console */
import { graphql } from "gatsby";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { browserName, browserVersion } from "react-device-detect";
import { Helmet } from "react-helmet";
import { BiInfoCircle, BiWindowClose } from "react-icons/bi";
import urljoin from "url-join";
import config from "../../data/SiteConfig";
import Footer from "../components/Footer/Footer";
import PostTags from "../components/PostTags/PostTags";
// import PostCategories from "../components/PostCategories/PostCategories";
import SEO from "../components/SEO/SEO";
import DIMENSIONS from "../helpers/DIMENSIONS";
import useWindowDimensions from "../helpers/GetWindowDimensions";
import Layout from "../layout";
import * as S from "../styles/styles.css";
import "./b16-tomorrow-dark.css";
import "./post.css";

export default function PostTemplate({ data, pageContext }) {
	const bodyPost = useRef(null);

	const { width: windowWidth, height: windowHeight } = useWindowDimensions();
	const [infoOpen, setInfoOpen] = useState(false);
	const [loaded, setIsLoaded] = useState(false);

	const { slug } = pageContext;
	const postNode = data.markdownRemark;
	const post = postNode.frontmatter;
	if (!post.id) {
		post.id = slug;
	}
	const dateFormatted = DateTime.fromISO(post.date);

	useEffect(() => {
		setIsLoaded(true);
		console.log(`${browserName} ${browserVersion}`);
	}, []);

	const postPath = `${_.camelCase(slug)}`;
	const pathPrefix = "/static.html?&zoomify=";
	const url =
		loaded && postPath && pathPrefix && urljoin(pathPrefix + postPath);

	return (
		<Layout location="artworks" title={`${post.title}`} postView>
			<Helmet
				bodyAttributes={
					loaded && {
						style: "overflow: hidden",
					}
				}
			>
				<title>{`${post.title} | ${config.siteTitle}`}</title>
			</Helmet>
			<Row
				className="bodyPost__"
				ref={bodyPost}
				style={{
					height: "100%",
				}}
			>
				<SEO postPath={slug} postNode={postNode} postSEO />
				<Container
					fluid
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					{!infoOpen && windowWidth < DIMENSIONS.breakpoints.mobile && (
						<Row style={{ height: !infoOpen && "100px" }}>
							{" "}
							<S.PostCard style={{ position: "unset" }} key={1}>
								{/* eslint-disable-next-line react/no-danger */}
								<Card.Title>
									<div style={{ height: "1em", display: "block" }} />
									<h4 className="mx-3">
										<span>{post.title}</span>
										<S.DatePost>&nbsp;{dateFormatted.c.year}</S.DatePost>
									</h4>
									<h5 className="mx-3 post-category">{post.category}</h5>
									<span style={{ position: "relative" }}>
										{infoOpen && (
											<p style={{ fontSize: "13px", paddingLeft: "1rem" }}>
												* Dates are in progress of being updated.
											</p>
										)}
									</span>
								</Card.Title>
							</S.PostCard>
						</Row>
					)}
					<Row style={{ flex: 1 }}>
						<S.PostCol
							open={infoOpen}
							xs={{ span: 12, order: "last" }}
							sm={{ span: 4, order: "last" }}
							md={{ span: 3, order: "first" }}
						>
							<S.PostCard
								key={2}
								style={{
									height: infoOpen ? "91%" : "100%",
								}}
							>
								{/* eslint-disable-next-line react/no-danger */}

								<Card.Title>
									<div style={{ height: "1em", display: "block" }} />
									<h4 className="mx-3">
										<span>{post.title}</span>
										<S.DatePost>&nbsp;{dateFormatted.c.year}</S.DatePost>
									</h4>
									<h5 className="mx-3 post-category">{post.category}</h5>
									<span style={{ position: "relative" }}>
										<p style={{ fontSize: "13px", paddingLeft: "1rem" }}>
											* Dates are in progress of being updated.
										</p>
									</span>
								</Card.Title>
								<Card.Body
									dangerouslySetInnerHTML={{ __html: postNode.html }}
								/>
								<Card.Footer>
									<div className="post-meta">
										<PostTags tags={post.tags} />
									</div>
								</Card.Footer>
							</S.PostCard>
						</S.PostCol>
						<Col
							xs={{ span: 12, order: "first" }}
							sm={{ span: 8, order: "first" }}
							md={{ span: 9, order: "last" }}
							style={{ position: "relative", height: "100%", padding: 0 }}
						>
							{/* Body */}
							{loaded && (
								<iframe
									title="zoomFrame"
									src={url}
									style={{
										border: "0 #ffffff none",
										borderRadius:
											windowWidth > DIMENSIONS.breakpoints.mobile && "5px",
										boxShadow: "1px 1px black",
										height: "100%",
									}}
									name={pathPrefix}
									scrolling="no"
									frameBorder="1"
									marginHeight="0px"
									marginWidth="0px"
									width="100%"
									heigh="100%"
									sandbox="allow-scripts"
									allowFullScreen
								/>
							)}
						</Col>
					</Row>
					<Footer config={config} postPath={slug} postNode={postNode} />
				</Container>
			</Row>
			<S.InfoButton
				open={infoOpen}
				onClick={() =>
					infoOpen === false ? setInfoOpen(true) : setInfoOpen(false)
				}
			>
				<p>
					{!infoOpen ? <BiInfoCircle size={30} /> : <BiWindowClose size={30} />}
				</p>
			</S.InfoButton>
		</Layout>
	);
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
