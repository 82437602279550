import { Link } from "gatsby";
import _ from "lodash";
import React from "react";
import { CgHashtag } from "react-icons/cg";
import styled from "styled-components";
import COLORS from "../../styles/COLORS";

const TagButton = styled.a`
  padding: 0.417rem 0.417rem 0.417rem 0.917rem;
  margin: 0 10px 0 0;
  position: relative;
  color: ${COLORS.mediumGrey};
  background: #00000010;

  font-size: 0.75rem;
  font-weight: bold;
  text-decoration: none;

  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.4);

  border-top: 1px solid ${COLORS.lightGrey};
  border-right: 1px solid ${COLORS.lightGrey};
  border-bottom: 1px solid ${COLORS.lightGrey};

  border-radius: 0.66em;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4),
    0 1px 1px rgba(0, 0, 0, 0.1);

  z-index: 1;

  &:hover {
    color: ${COLORS.mediumGrey};
    text-decoration: none;
  }

`;

function PostTags({ tags }) {
  return (
    <div className="post-tag-container">
   <h5><CgHashtag/></h5>
      {tags &&
        tags.map((tag) => (
          <Link
            key={tag}
            style={{ textDecoration: "none" }}
            to={`/tags/${_.kebabCase(tag)}`}
          >
            <TagButton type="button">#{tag}</TagButton>
          </Link>
        ))}
    </div>
  );
}

export default PostTags;
